import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { graphql, Link } from "gatsby"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { Socials } from "../components/Socials"
import { GetLatestBlogPostsQuery } from "../graphqlTypes"
import { TypedText } from "../TypedText"


interface BlogIndexProps {
  data: GetLatestBlogPostsQuery
  location?: PageLocation, // Not available during SSR
}

const BlogIndex: React.FC<BlogIndexProps> = ({ data }) => {
  if (!data.site) {
    throw new Error("Error in the graphql query response");
  }

  const { title: siteTitle, description: siteDescription } = data.site.siteMetadata

  return (
    <Layout>
      <Seo title={siteTitle} description={siteDescription} />
      <Box
        className="Index__banner"
        component="section"
        justifyContent="center"
        maxWidth="md"
        my="2rem"
        minHeight="40vh"
        display="flex"
        alignItems="center"
      >
        <Banner />
      </Box>
      <Box
        className="Index__content"
        display="grid"
        gridTemplateColumns={{ xs: "1fr", sm: "repeat(3, 1fr)" }}
        gap="1rem"
        justifyContent="center"
        my="4rem"
      >
        <ContentCard
          title="The blog"
          text="Articles about programming, architecture, best practices, and other software enginnering and productivity related topics!"
          image={{
            src: "/assets/images/blog-page-cover.png",
            alt: "The Dev Tavern blog"
          }}
          action={{ label: "Read", link: "/blog" }}
        />

        <ContentCard
          title="Courses"
          text="Courses from the Tavern!"
          image={{
            src: "/assets/images/courses-page-cover.png",
            alt: "The Dev Tavern courses",
          }}
          action={{ label: "Browse", link: "/courses" }}
        />

        <ContentCard
          title="The Newsletter"
          text="Subscribe to the newsletter to hear about the latest toolings I discovered, books I read, and other goodies!"
          image={{
            src: "/assets/images/newsletter-page-cover.png",
            alt: "The Dev Tavern newsletter"
          }}
          action={{ label: "Subscribe", link: "/newsletter" }}
        />
      </Box>
    </Layout >
  )
}

interface BannerProps {
}

const Banner: React.FC<BannerProps> = () => {
  return (
    <Box textAlign="center">
      <Typography component="h1" variant="h1" fontWeight="bold">
        The Dev Tavern
      </Typography>
      <Box my="1rem">
        <Typography component="div" variant="h4">A place to discuss</Typography>
        <Typography component="div" variant="h4" minHeight="2.5rem">
          <TypedText options={{
            strings: ["coding!", "architecture!", "toolings!", "geeky stuff!"],
            typeSpeed: 120,
            startDelay: 500,
            backSpeed: 60,
            showCursor: false,
          }} />
          {/* A place to discuss coding, architecture, toolings and a lot of fun geeky stuff! */}
        </Typography>
      </Box>
      <Box my="1rem">
        <Typography component="div" variant="h6" color="text.secondary" gutterBottom>
          Want to connect and stay up to date with the latest content?
        </Typography>
        <Socials size="large" sx={{ mx: "auto" }} />
      </Box>
    </Box>
  )
}

interface ContentCardProps {
  title: string
  text: string
  image: {
    src: string,
    alt: string,
  }
  action: {
    label: string,
    link: string
  }
}

const ContentCard: React.FC<ContentCardProps> = (props) => (
  <Card sx={{ borderRadius: "0.5rem", display: "flex", flexDirection: "column" }}>
    <CardMedia
      component="img"
      height="140"
      image={props.image.src}
      alt={props.image.alt}
      sx={{ objectFit: "contain", my: "2rem" }}
    />
    <CardContent sx={{ flexGrow: 1 }}>
      <Typography gutterBottom variant="h5" component="div">
        {props.title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {props.text}
      </Typography>
    </CardContent>
    <CardActions>
      <Link to={props.action.link} style={{ textDecoration: "none", color: "inherit" }}>
        <Button size="small">{props.action.label}</Button>
      </Link>
    </CardActions>
  </Card>
)

export default BlogIndex

export const pageQuery = graphql`
  query GetLatestBlogPosts {
    site {
      siteMetadata {
        title
        description
        author {
          name
        }
      }
    }

    allMdx(sort: {order: DESC, fields: [frontmatter___date]}, limit: 10) {
      edges {
        node {
          id
          fields {
            defaultSlug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description {
              short
              long
            }
            tags
            banner {
              childImageSharp {
                gatsbyImageData(height: 600)
              }
            }
          }
        }
      }
    }
  }
`
