import React from "react";
import Typed, { TypedOptions } from "typed.js";

export interface TypedTextProps {
	options: TypedOptions
}

export const TypedText: React.FC<TypedTextProps> = (props) => {
	const el = React.useRef<HTMLElement>(null);
	const typed = React.useRef<Typed>();

	React.useEffect(() => {
		// elRef refers to the <span> rendered below
		if (!el.current) {
			throw new Error("cannot used Typed as the node reference is not set")
		}

		typed.current = new Typed(el.current, props.options);

		return () => {
			// Make sure to destroy Typed instance during cleanup
			// to prevent memory leaks
			typed.current?.destroy();
		}
	}, [props.options])


	return (
		<span style={{ whiteSpace: 'pre' }} ref={el} />
	)
}